@import '../../styles/media';

@keyframes gradient-slosh {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

.contest-chip {
  display: inline-block;
  background: #DEDEDE;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background 0.1s ease-in-out;

  &:hover {
    outline: none !important;
    @include tablet {
      background: linear-gradient(-15deg, #FFF, #DEDEDE, #FFF);
      background-size: 200% 200%;
      animation: gradient-slosh 3s linear infinite;
    }
  }

  &:focus {
    outline: none !important;
  }

  .contest-chip-content {
    display: flex;
    align-items: center;

    img {
      height: 15px;
      padding-right: 10px;
      border-right: 1px solid #BBB;

      @include phone {
        height: 20px;
      }
    }

    &__label {
      margin-left: 10px;
      font-size: 10px;
      display: inline-block;
      max-width: 100%;
      line-height: 1.2em;
      max-height: 2.4em;
      overflow: hidden;
      word-break: break-word;

      @include phone {
        font-size: 12px;
      }
    }
  }
}
