@import '../../styles/media';

.Home {
  // Christmas lights banner
  // .festive-lights {
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   width: 100%;
  //   height: 50px;
  //   background-position-x: center;
  //   background-size: contain;
  //   background-image: url('../../assets/images/festive_lights.gif');
  //   background-repeat: repeat-x;

  //   @include phone {
  //     height: 60px;
  //   }

  //   @include tablet {
  //     height: 70px;
  //   }
  // }

  .splash {
    overflow: hidden;
    color: #FFF;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    height: 85vh;
    background-color: #3b80f8;

    @include phone {
      height: 100vh;
    }
    
    .splash-bg {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;

      @keyframes cheer {
        0% {
          transform: translateY(0px);
        }
        50% {
          transform: translateY(10px);
        }
        100%{
          transform: translateY(0px);
        }
      }

      .image-container {
        position: absolute;
        bottom: 10px;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 130%;
        overflow: hidden;

        @include tablet {
          bottom: 50px;
        }

        &.back {
          animation: cheer 2s ease-in-out infinite;
          animation-delay: 0.3s;
        }

        &.front {
          animation: cheer 2s ease-in-out infinite;
        }

        img {
          width: 100%;
          min-width: 830px;
          @include tablet {
            min-width: 1475px;
          }
        }
      }
    }

    .splash-content {
      z-index: 1;
      width: 100%;
      max-width: 1000px;
      padding: 0 15px 15px 15px;
      margin-top: -10%;

      svg {
        height: 30px;
        @include phone {
          height: 50px;
        }
      }

      p {
        font-size: 14px;
        padding: 10px 0;
        margin-left: 7px;

        @include phone {
          padding: 18px 0 20px;
          font-size: 24px;
          margin-left: 10px;
        }
      }

      a {
        display:inline-block;
        animation: point .75s ease-out infinite;
        font-size: 28px;
      }

      @keyframes point {
        0%, 100% {
          transform: translate(0, 0);
        }

        50% {
          transform: translate(0, 2px);
        }
      }
    }

    .splash-stage {
      max-width: 150%;
      position: absolute;
      bottom: 0;
      @include phone {
        max-width: 100%;
      }
    }
  }

  @keyframes infiniteScrollBg {
    0% {
      transform: translateY(0%);
    }
    100%{
      transform: translateY(-50%);
    }
  }

  .winner-wall-bg {
    animation: infiniteScrollBg 60s linear infinite;
  }
}
