@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  margin:0;
  padding:0;
  height:100%;
}

.text-facebook { color: #3b5998; }
.bg-facebook { background-color: #3b5998; }

.text-spotify { color: #25C95E; }
.bg-spotify { background-color: #25C95E; }

.text-twitter { color: #55acee; }
.bg-twitter { background-color: #55acee; }

.text-youtube { color: #FF0000; }
.bg-youtube { background-color: #FF0000; }

.text-google { color: #4285F4; }
.bg-google { background-color: #4285F4; }

.text-soundcloud { color: #ff551b; }
.bg-soundcloud { background-color: #ff551b; }

.divider-fade-right {
  @apply w-full h-px;
  border-radius: 1px;
  background: -webkit-linear-gradient( to right, #d2d6dc, white );
  background:    -moz-linear-gradient( to right, #d2d6dc, white );
  background:      -o-linear-gradient( to right, #d2d6dc, white );
  background:         linear-gradient( to right, #d2d6dc, white );
}

.bg-blue-gradient {
  background-color: #2F67F3;
  background-image: -webkit-linear-gradient( to right, #2F67F3, #0638AC );
  background-image:    -moz-linear-gradient( to right, #2F67F3, #0638AC );
  background-image:      -o-linear-gradient( to right, #2F67F3, #0638AC );
  background-image:         linear-gradient( to right, #2F67F3, #0638AC );
}

.link-blue {
  @apply text-blue-600;
}

.link-blue:hover {
  @apply text-blue-500;
}

.link-gray {
  @apply text-gray-500;
}

.link-gray:hover {
  @apply text-blue-700;
}

.Footer__bg-mob {
  background: url('./assets/images/footer_back_mob.svg') no-repeat bottom center;
  background-size: 100%;
}

// new button
.bg-blue {
  background-color: #2F67F3;
}

.btn-base {
  @apply inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:col-start-2;
}

.btn-primary {
  @apply btn-base border-gray-300 bg-blue text-white hover:bg-blue-500 focus:ring-indigo-500;
}

.btn {
  @apply px-8 py-2 font-medium text-sm inline-block text-center box-border transition-colors duration-100 cursor-pointer whitespace-nowrap;
}

.btn-sm {
  @apply px-2 text-xs inline-block text-center box-border transition-colors duration-100 cursor-pointer whitespace-nowrap;
}

.btn-sm:focus {
  outline: none;
  @apply ring;
}

.btn:focus {
  outline: none;
  @apply ring;
}

.btn-blue {
  @apply bg-blue-700;
}
.btn-blue:hover {
  @apply bg-blue-400; 
}

.btn-green {
  background-color: #24CF5F;
}

.btn-green:hover {
  background-color: #5de38b;
}

.btn-sports {
  background-color: #15AA69;
}
.btn-sports:hover {
  background-color: #26D37C;
}

.btn-border {
  @apply border border-black bg-transparent;
}
.btn-border:hover {
  @apply bg-black text-white;
}

.btn-border-red {
  @apply border border-red-500 bg-transparent text-red-500;
}
.btn-border-red:hover {
  @apply bg-red-500 text-white;
}

.btn-disabled {
  @apply border border-gray-500 bg-transparent text-gray-500;
}
.btn-disabled:hover {
  @apply cursor-not-allowed
}

.btn-white {
  @apply bg-white; 
}
.btn-white:hover {
  @apply bg-black text-white;
}

.btn-round {
  @apply rounded-full;
}

@screen sm {
  .Footer__bg-mob {
    background: none;
  }

  .Footer__bg-arch {
    background: url('./assets/images/footer_back.svg') no-repeat bottom ;
    background-size: contain;
    background-position: 99% 100%;
  }
}

.text-sm-clamp-4 {
  @apply text-sm leading-5 h-20 overflow-hidden break-words;
}

/* Loading Icon */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.icon-loading {
  display: inline-block;
  animation: spin 2.25s linear infinite;
}

.Loading > img {
  animation: spin 2s linear infinite;
}

/* Google Place Autocomplete */
.pac-container {
  z-index: 1301;
  cursor: pointer;
  border-top: none;
}

.pac-container .pac-item {
  border-top: none;
  padding: 4px;
}


/* TODO: move this to it's own file */
.AwsMarkdown h1, h2, h3, h4, h5, h6 {
  @apply leading-tight font-medium mb-4;
}

.AwsMarkdown h1 {
  @apply text-3xl;
}
@screen sm {
  .AwsMarkdown h1 {
    @apply text-4xl;
  }
}

.AwsMarkdown h2 {
  @apply text-2xl;
}

.AwsMarkdown h3 {
  @apply text-xl mt-8;
}

.AwsMarkdown h4 {
  @apply text-lg mt-8;
}

.AwsMarkdown h5 {
  @apply text-base mt-8;
}

.AwsMarkdown p {
  @apply mb-4 text-gray-700;
}

.AwsMarkdown ul {
  @apply list-disc list-inside;
}

.AwsMarkdown ol {
  @apply pl-5 mb-8 list-decimal list-outside;
}

.AwsMarkdown ol ol {
  @apply list-alpha;
}

.AwsMarkdown ol ol ol {
  @apply list-roman;
}

.AwsMarkdown li {
  @apply my-2 text-gray-700;
}

.AwsMarkdown li p {
  @apply inline;
}

.AwsMarkdown sup {
  @apply text-sm;
}

.AwsMarkdown strong {
  @apply font-bold;
}

.AwsMarkdown table {
  font-size: 0.5rem;
  @apply my-2 w-full text-gray-700 bg-white table-fixed;
}

@screen sm { 
  .AwsMarkdown table {
    @apply text-xs table-auto;
  }
}

.AwsMarkdown table th {
  @apply bg-gray-200 p-2;
}

.AwsMarkdown table td {
  @apply p-2 border-b break-words;
}

.AwsMarkdown table td:nth-child(2n) {
  @apply bg-gray-50;
}

.AwsMarkdown a {
  @apply break-words text-blue-600;
}
.AwsMarkdown a:hover {
  @apply text-blue-400;
}
.AwsMarkdown hr {
  @apply my-8 border-gray-300;
}

.Sports {
  background: url('./assets/images/sports_bg.png') no-repeat top center;
  background-size: 100% 100%;
}

@font-face {
  font-family: 'United-Italic';
  src: url('./assets/fonts/UnitedItalicRgBd.ttf');
}

@font-face {
  font-family: 'United';
  src: url('./assets/fonts/UnitedSansRgMd.ttf');
}

.united {
  font-family: 'United';
}

.united-italic {
  font-family: 'United-Italic';
}

.text-sports { color: #26D37C; }
.bg-sports { background-color: #26D37C;}

.PhoneInput input {
  outline: none !important;
  @apply sm:text-sm;
}

.react-select {
  &__control {
    border-color: #D1D5DB !important;
  }

  &__placeholder {
    color: #9ca3af !important;
    @apply text-sm;
  }

  &__input {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }
}