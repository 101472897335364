@font-face {
  font-family: 'tunespeak';
  src:  url('fonts/tunespeak.eot?qounla');
  src:  url('fonts/tunespeak.eot?qounla#iefix') format('embedded-opentype'),
    url('fonts/tunespeak.ttf?qounla') format('truetype'),
    url('fonts/tunespeak.woff?qounla') format('woff'),
    url('fonts/tunespeak.svg?qounla#tunespeak') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tunespeak' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cake:before {
  content: "\e969";
}
.icon-star-pick:before {
  content: "\e965";
}
.icon-upvote-outline:before {
  content: "\e95b";
}
.icon-upvote:before {
  content: "\e960";
}
.icon-powered-by:before {
  content: "\e95f";
}
.icon-comment-disabled:before {
  content: "\e910";
}
.icon-settings-spotify:before {
  content: "\e939";
}
.icon-settings-spotify-web:before {
  content: "\e93d";
}
.icon-check-spotify:before {
  content: "\e93c";
}
.icon-entry:before {
  content: "\e93a";
}
.icon-i-message:before {
  content: "\e935";
}
.icon-fb-messenger:before {
  content: "\e936";
}
.icon-play:before {
  content: "\e926";
}
.icon-pause:before {
  content: "\e929";
}
.icon-circle-check:before {
  content: "\e923";
}
.icon-options:before {
  content: "\e91f";
}
.icon-brackets:before {
  content: "\e964";
}
.icon-reply-all:before {
  content: "\e966";
}
.icon-download:before {
  content: "\e918";
}
.icon-edit:before {
  content: "\e915";
}
.icon-loading:before {
  content: "\e914";
}
.icon-question:before {
  content: "\e913";
}
.icon-alert:before {
  content: "\e912";
}
.icon-clipboard:before {
  content: "\e90f";
}
.icon-world:before {
  content: "\e900";
}
.icon-notification:before {
  content: "\e901";
}
.icon-tumblr:before {
  content: "\e902";
}
.icon-vevo:before {
  content: "\e903";
}
.icon-soundcloud:before {
  content: "\e904";
}
.icon-tunespeak:before {
  content: "\e942";
}
.icon-arrow-down:before {
  content: "\e905";
}
.icon-arrow-left:before {
  content: "\e906";
}
.icon-arrow-right:before {
  content: "\e907";
}
.icon-arrow-up:before {
  content: "\e908";
}
.icon-chevron-left:before {
  content: "\e909";
}
.icon-chevron-right:before {
  content: "\e90a";
}
.icon-refresh:before {
  content: "\e947";
}
.icon-not-allowed:before {
  content: "\e94a";
}
.icon-target:before {
  content: "\e94b";
}
.icon-locate:before {
  content: "\e94c";
}
.icon-place:before {
  content: "\e94d";
}
.icon-menu:before {
  content: "\e90b";
}
.icon-elipses:before {
  content: "\e950";
}
.icon-check:before {
  content: "\e90c";
}
.icon-x:before {
  content: "\e90d";
}
.icon-circle:before {
  content: "\e954";
}
.icon-token:before {
  content: "\e955";
}
.icon-circle-x:before {
  content: "\e90e";
}
.icon-data:before {
  content: "\e916";
}
.icon-leave:before {
  content: "\e956";
}
.icon-calendar:before {
  content: "\e917";
}
.icon-facebook:before {
  content: "\e919";
}
.icon-spotify:before {
  content: "\e91a";
}
.icon-instagram:before {
  content: "\e91c";
}
.icon-itunes:before {
  content: "\e958";
}
.icon-youtube:before {
  content: "\e91d";
}
.icon-phone:before {
  content: "\e91e";
}
.icon-photo:before {
  content: "\e921";
}
.icon-mail:before {
  content: "\e922";
}
.icon-music:before {
  content: "\e924";
}
.icon-dollar:before {
  content: "\e925";
}
.icon-clock:before {
  content: "\e927";
}
.icon-power:before {
  content: "\e92a";
}
.icon-search:before {
  content: "\e92b";
}
.icon-settings:before {
  content: "\e92d";
}
.icon-merch:before {
  content: "\e92e";
}
.icon-ticket:before {
  content: "\e92f";
}
.icon-star:before {
  content: "\e930";
}
.icon-thumbs-up:before {
  content: "\e95a";
}
.icon-trophy:before {
  content: "\e937";
}
.icon-user:before {
  content: "\e938";
}
.icon-group:before {
  content: "\e961";
}
.icon-raffle-box:before {
  content: "\e93e";
}
.icon-raffle:before {
  content: "\e93f";
}
.icon-merit:before {
  content: "\e95c";
}
.icon-vip:before {
  content: "\e95d";
}
.icon-female:before {
  content: "\e940";
}
.icon-male:before {
  content: "\e941";
}
.icon-filter:before {
  content: "\e94f";
}
.icon-send:before {
  content: "\e943";
}
.icon-reply:before {
  content: "\e948";
}
.icon-album:before {
  content: "\e931";
}
.icon-link:before {
  content: "\e92c";
}
.icon-plus:before {
  content: "\e932";
}
.icon-chevron-down:before {
  content: "\e93b";
}
.icon-q:before {
  content: "\e945";
}
.icon-quote-open:before {
  content: "\e946";
}
.icon-quote-close:before {
  content: "\e949";
}
.icon-comment-add:before {
  content: "\e94e";
}
.icon-comment:before {
  content: "\e951";
}
.icon-comment-outline:before {
  content: "\e953";
}
.icon-plays-left:before {
  content: "\e952";
}
.icon-fullscreen:before {
  content: "\e957";
}
.icon-credit-card:before {
  content: "\e928";
}
.icon-insta-comment:before {
  content: "\e959";
}
.icon-insta-heart:before {
  content: "\e95e";
}
.icon-lock:before {
  content: "\e944";
}
.icon-biz:before {
  content: "\e934";
}
.icon-code:before {
  content: "\e933";
}
.icon-follow:before {
  content: "\e9da";
}
.icon-share:before {
  content: "\e920";
}
.icon-help:before {
  content: "\e911";
}
.icon-trash:before {
  content: "\e963";
}
.icon-chevron-up:before {
  content: "\e962";
}
.icon-enlarge:before {
  content: "\e98b";
}
.icon-google:before {
  content: "\ea88";
}
.icon-film:before {
  content: "\e967";
}
.icon-printer:before {
  content: "\e968";
}
.icon-website:before {
  content: "\e9c9";
}
.icon-twitter:before {
  content: "\e91b";
}
