@import '../../styles/media';


@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100%{
    transform: scale(1);
  }
}

.favorite-heart {
  cursor: pointer;

  @include tablet {
    &:hover {
      animation: pulse 1s ease-in-out infinite;
    }
  }

  img {
    height: 26px;
    width: 26px;
    min-height: 26px;
    min-width: 26px;
  }
}
